<template>
  <div id="lay-menu">
    <el-menu :unique-opened="uniqueOpened" :default-openeds="openeds" :default-active="defaultActive" text-color="#fff"
      background-color="#262626" active-text-color="#fff" active-background-color="#EF9807" router>
      <el-menu-item v-for="(item, index) in itemMenuList" :key="item.funId" :index="String(item.requestPath)">
        <el-image class="icon" :src="item.iconUrl">
        </el-image>
        <span slot="title">{{ item.functionName }}</span>
      </el-menu-item>
      <el-submenu v-for="(item, index) in subMenuList" :key="item.funId" :index="String(index)">
        <template #title>
          <el-image class="icon" :src="item.iconUrl">
          </el-image>
          <span>{{ item.functionName }}</span>
        </template>
        <el-menu-item v-for="i in item.functionVos" :key="i.funId" :index="String(i.requestPath)">{{ i.functionName
        }}</el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  props: ["menulist"],
  data() {
    return {
      openeds: ["0"], //下拉菜单默认展开项
      defaultActive: this.$route.path,
      uniqueOpened: true, //是否开启手风琴模式
    };
  },
  computed: {
    subMenuList() {
      return this.menulist.filter(i => i.functionVos)
    },
    itemMenuList() {
      return this.menulist.filter(i => !i.functionVos)
    },
  }
};
</script>


<style lang="scss" scoped>
#lay-menu {
  width: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

// 滚动条宽度
::-webkit-scrollbar {
  width: 0px;
}

.el-menu {
  height: 100%;
  border: 0;
}

.icon {
  width: .16rem;
  height: .16rem;
  margin-right: .05rem;
}

.is-active {
  background: #EF9807 !important;
}
</style>